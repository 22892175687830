exports.components = {
  "component---src-pages-about-team-member-js": () => import("./../../../src/pages/about/team-member.js" /* webpackChunkName: "component---src-pages-about-team-member-js" */),
  "component---src-pages-podcasts-in-conversation-with-js": () => import("./../../../src/pages/podcasts/in-conversation-with.js" /* webpackChunkName: "component---src-pages-podcasts-in-conversation-with-js" */),
  "component---src-pages-podcasts-knocking-down-the-door-js": () => import("./../../../src/pages/podcasts/knocking-down-the-door.js" /* webpackChunkName: "component---src-pages-podcasts-knocking-down-the-door-js" */),
  "component---src-pages-podcasts-on-the-bookcase-js": () => import("./../../../src/pages/podcasts/on-the-bookcase.js" /* webpackChunkName: "component---src-pages-podcasts-on-the-bookcase-js" */),
  "component---src-pages-podcasts-sticks-and-thrones-js": () => import("./../../../src/pages/podcasts/sticks-and-thrones.js" /* webpackChunkName: "component---src-pages-podcasts-sticks-and-thrones-js" */),
  "component---src-pages-podcasts-the-notes-between-js": () => import("./../../../src/pages/podcasts/the-notes-between.js" /* webpackChunkName: "component---src-pages-podcasts-the-notes-between-js" */),
  "component---src-pages-share-news-urls-js": () => import("./../../../src/pages/share/news-urls.js" /* webpackChunkName: "component---src-pages-share-news-urls-js" */),
  "component---src-pages-team-index-js": () => import("./../../../src/pages/team/index.js" /* webpackChunkName: "component---src-pages-team-index-js" */),
  "component---src-templates-404-page-js": () => import("./../../../src/templates/404-page.js" /* webpackChunkName: "component---src-templates-404-page-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-book-js": () => import("./../../../src/templates/book.js" /* webpackChunkName: "component---src-templates-book-js" */),
  "component---src-templates-celebrating-2-years-js": () => import("./../../../src/templates/celebrating-2-years.js" /* webpackChunkName: "component---src-templates-celebrating-2-years-js" */),
  "component---src-templates-celebrating-3-years-js": () => import("./../../../src/templates/celebrating-3-years.js" /* webpackChunkName: "component---src-templates-celebrating-3-years-js" */),
  "component---src-templates-celebrating-4-years-js": () => import("./../../../src/templates/celebrating-4-years.js" /* webpackChunkName: "component---src-templates-celebrating-4-years-js" */),
  "component---src-templates-celebrating-one-year-js": () => import("./../../../src/templates/celebrating-one-year.js" /* webpackChunkName: "component---src-templates-celebrating-one-year-js" */),
  "component---src-templates-celebration-of-women-international-womens-day-js": () => import("./../../../src/templates/celebration-of-women-international-womens-day.js" /* webpackChunkName: "component---src-templates-celebration-of-women-international-womens-day-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-event-js": () => import("./../../../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-events-page-js": () => import("./../../../src/templates/events-page.js" /* webpackChunkName: "component---src-templates-events-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-london-jazz-festival-2021-js": () => import("./../../../src/templates/london-jazz-festival-2021.js" /* webpackChunkName: "component---src-templates-london-jazz-festival-2021-js" */),
  "component---src-templates-magazines-page-js": () => import("./../../../src/templates/magazines-page.js" /* webpackChunkName: "component---src-templates-magazines-page-js" */),
  "component---src-templates-new-release-js": () => import("./../../../src/templates/new-release.js" /* webpackChunkName: "component---src-templates-new-release-js" */),
  "component---src-templates-new-releases-page-js": () => import("./../../../src/templates/new-releases-page.js" /* webpackChunkName: "component---src-templates-new-releases-page-js" */),
  "component---src-templates-news-page-js": () => import("./../../../src/templates/news-page.js" /* webpackChunkName: "component---src-templates-news-page-js" */),
  "component---src-templates-news-post-js": () => import("./../../../src/templates/news-post.js" /* webpackChunkName: "component---src-templates-news-post-js" */),
  "component---src-templates-on-the-bookcase-page-js": () => import("./../../../src/templates/on-the-bookcase-page.js" /* webpackChunkName: "component---src-templates-on-the-bookcase-page-js" */),
  "component---src-templates-on-the-playlist-page-js": () => import("./../../../src/templates/on-the-playlist-page.js" /* webpackChunkName: "component---src-templates-on-the-playlist-page-js" */),
  "component---src-templates-playlist-js": () => import("./../../../src/templates/playlist.js" /* webpackChunkName: "component---src-templates-playlist-js" */),
  "component---src-templates-podcast-series-in-conversation-with-js": () => import("./../../../src/templates/podcast-series-in-conversation-with.js" /* webpackChunkName: "component---src-templates-podcast-series-in-conversation-with-js" */),
  "component---src-templates-podcast-series-knocking-down-the-door-js": () => import("./../../../src/templates/podcast-series-knocking-down-the-door.js" /* webpackChunkName: "component---src-templates-podcast-series-knocking-down-the-door-js" */),
  "component---src-templates-podcast-series-on-the-bookcase-js": () => import("./../../../src/templates/podcast-series-on-the-bookcase.js" /* webpackChunkName: "component---src-templates-podcast-series-on-the-bookcase-js" */),
  "component---src-templates-podcast-series-page-in-conversation-with-js": () => import("./../../../src/templates/podcast-series-page-in-conversation-with.js" /* webpackChunkName: "component---src-templates-podcast-series-page-in-conversation-with-js" */),
  "component---src-templates-podcast-series-page-knocking-down-the-door-js": () => import("./../../../src/templates/podcast-series-page-knocking-down-the-door.js" /* webpackChunkName: "component---src-templates-podcast-series-page-knocking-down-the-door-js" */),
  "component---src-templates-podcast-series-page-on-the-bookcase-js": () => import("./../../../src/templates/podcast-series-page-on-the-bookcase.js" /* webpackChunkName: "component---src-templates-podcast-series-page-on-the-bookcase-js" */),
  "component---src-templates-podcast-series-page-sticks-and-thrones-js": () => import("./../../../src/templates/podcast-series-page-sticks-and-thrones.js" /* webpackChunkName: "component---src-templates-podcast-series-page-sticks-and-thrones-js" */),
  "component---src-templates-podcast-series-page-the-notes-between-js": () => import("./../../../src/templates/podcast-series-page-the-notes-between.js" /* webpackChunkName: "component---src-templates-podcast-series-page-the-notes-between-js" */),
  "component---src-templates-podcast-series-sticks-and-thrones-js": () => import("./../../../src/templates/podcast-series-sticks-and-thrones.js" /* webpackChunkName: "component---src-templates-podcast-series-sticks-and-thrones-js" */),
  "component---src-templates-podcast-series-the-notes-between-js": () => import("./../../../src/templates/podcast-series-the-notes-between.js" /* webpackChunkName: "component---src-templates-podcast-series-the-notes-between-js" */),
  "component---src-templates-podcasts-page-js": () => import("./../../../src/templates/podcasts-page.js" /* webpackChunkName: "component---src-templates-podcasts-page-js" */),
  "component---src-templates-projects-page-js": () => import("./../../../src/templates/projects-page.js" /* webpackChunkName: "component---src-templates-projects-page-js" */),
  "component---src-templates-share-news-urls-page-js": () => import("./../../../src/templates/share-news-urls-page.js" /* webpackChunkName: "component---src-templates-share-news-urls-page-js" */),
  "component---src-templates-supporter-js": () => import("./../../../src/templates/supporter.js" /* webpackChunkName: "component---src-templates-supporter-js" */),
  "component---src-templates-supporters-page-js": () => import("./../../../src/templates/supporters-page.js" /* webpackChunkName: "component---src-templates-supporters-page-js" */),
  "component---src-templates-team-member-js": () => import("./../../../src/templates/team-member.js" /* webpackChunkName: "component---src-templates-team-member-js" */),
  "component---src-templates-team-member-page-js": () => import("./../../../src/templates/team-member-page.js" /* webpackChunkName: "component---src-templates-team-member-page-js" */),
  "component---src-templates-testimonial-js": () => import("./../../../src/templates/testimonial.js" /* webpackChunkName: "component---src-templates-testimonial-js" */),
  "component---src-templates-women-in-jazz-media-at-the-efg-london-jazz-festival-js": () => import("./../../../src/templates/women-in-jazz-media-at-the-efg-london-jazz-festival.js" /* webpackChunkName: "component---src-templates-women-in-jazz-media-at-the-efg-london-jazz-festival-js" */),
  "component---src-templates-women-in-jazz-media-magazine-december-2021-js": () => import("./../../../src/templates/women-in-jazz-media-magazine-december-2021.js" /* webpackChunkName: "component---src-templates-women-in-jazz-media-magazine-december-2021-js" */),
  "component---src-templates-women-in-jazz-media-magazine-december-2022-js": () => import("./../../../src/templates/women-in-jazz-media-magazine-december-2022.js" /* webpackChunkName: "component---src-templates-women-in-jazz-media-magazine-december-2022-js" */),
  "component---src-templates-women-in-jazz-media-magazine-december-2023-js": () => import("./../../../src/templates/women-in-jazz-media-magazine-december-2023.js" /* webpackChunkName: "component---src-templates-women-in-jazz-media-magazine-december-2023-js" */),
  "component---src-templates-women-in-jazz-media-magazine-december-2024-js": () => import("./../../../src/templates/women-in-jazz-media-magazine-december-2024.js" /* webpackChunkName: "component---src-templates-women-in-jazz-media-magazine-december-2024-js" */),
  "component---src-templates-women-in-jazz-media-magazine-july-2022-js": () => import("./../../../src/templates/women-in-jazz-media-magazine-july-2022.js" /* webpackChunkName: "component---src-templates-women-in-jazz-media-magazine-july-2022-js" */),
  "component---src-templates-women-in-jazz-media-magazine-july-2023-js": () => import("./../../../src/templates/women-in-jazz-media-magazine-july-2023.js" /* webpackChunkName: "component---src-templates-women-in-jazz-media-magazine-july-2023-js" */),
  "component---src-templates-women-in-jazz-media-magazine-july-2024-js": () => import("./../../../src/templates/women-in-jazz-media-magazine-july-2024.js" /* webpackChunkName: "component---src-templates-women-in-jazz-media-magazine-july-2024-js" */),
  "component---src-templates-women-in-jazz-media-magazine-march-2022-js": () => import("./../../../src/templates/women-in-jazz-media-magazine-march-2022.js" /* webpackChunkName: "component---src-templates-women-in-jazz-media-magazine-march-2022-js" */),
  "component---src-templates-women-in-jazz-media-magazine-march-2023-js": () => import("./../../../src/templates/women-in-jazz-media-magazine-march-2023.js" /* webpackChunkName: "component---src-templates-women-in-jazz-media-magazine-march-2023-js" */),
  "component---src-templates-women-in-jazz-media-magazine-march-2024-js": () => import("./../../../src/templates/women-in-jazz-media-magazine-march-2024.js" /* webpackChunkName: "component---src-templates-women-in-jazz-media-magazine-march-2024-js" */)
}

